import React from "react";
import { Accordion } from "react-bootstrap";
import { Link, useResolvedPath } from "react-router-dom";
import adminRouteMap from '../../../routes/Admin/adminRouteMap';
import ImageElement from "../../ImageElement";
import SimpleBar from "simplebar-react";

function AdminSidebar({ closeLeftSidebar, extraClassName = '' }) {
  const { pathname } = useResolvedPath();

  return (
      <div className={`sidebar ${extraClassName}`}>
        <div className="navbar-brand d-flex align-items-center justify-content-between">
          <Link to={adminRouteMap.DASHBOARD.path} className="logo">
            <ImageElement source="logo_light.svg" style={{ width: '150px' }} alt="logo" />
          </Link>
          <Link to='#' onClick={closeLeftSidebar} className="closeLeftSidebar d-flex d-xl-none">
            <em className="icon icon-cross" />
          </Link>
        </div>
        <SimpleBar className="sidebar_menu">
          <Accordion className="bg-transparent rounded-0" defaultActiveKey={(pathname === adminRouteMap.PRIVACYPOLICY.path || pathname === adminRouteMap.TERMSANDCONDITIONS.path ? "6" : "0")}>
            <ul className="list-unstyled mainMenu">
              <li className="mainMenu-title">Menu</li>
              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="0">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.DASHBOARD.path} className={` ${pathname === adminRouteMap.DASHBOARD.path ? "active" : ""}`}>
                    <span className="icon icon-dashboard" />
                    Dashboard
                  </Link>
                </Accordion.Button>
              </Accordion.Item>
              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="1">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.USERS.path} className={` ${pathname === adminRouteMap.USERS.path || pathname === adminRouteMap.USERDETAILS.path ? "active" : ""}`}>
                    <span className="icon icon-users" />
                    Users
                  </Link>
                </Accordion.Button>
              </Accordion.Item>
              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="2">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.BLOGS.path} className={` ${pathname === adminRouteMap.BLOGS.path || pathname === adminRouteMap.USERDETAILS.path ? "active" : ""}`}>
                    <span className="icon icon-article" />
                    Blogs
                  </Link>
                </Accordion.Button>
              </Accordion.Item>
              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="2">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.PRODUCTS.path} className={` ${pathname === adminRouteMap.PRODUCTS.path || pathname === adminRouteMap.USERDETAILS.path ? "active" : ""}`}>
                    <span className="icon icon-article" />
                    Products
                  </Link>
                </Accordion.Button>
              </Accordion.Item>

              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="3">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.MANAGEORDER.path} className={` ${pathname === adminRouteMap.MANAGEORDER.path || pathname === adminRouteMap.MANAGEORDER.path ? "active" : ""}`}>
                    <span className="icon icon-users" />
                    Manage Orders
                  </Link>
                </Accordion.Button>
              </Accordion.Item>

              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="4">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.WHOLESALE.path} className={` ${pathname === adminRouteMap.WHOLESALE.path || pathname === adminRouteMap.WHOLESALE.path ? "active" : ""}`}>
                    <span className="icon icon-users" />
                    Wholesale Form Queries
                  </Link>
                </Accordion.Button>
              </Accordion.Item>

              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="5">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.CONTACTQUERY.path} className={` ${pathname === adminRouteMap.CONTACTQUERY.path ? "active" : ""}`}>
                    <span className="icon icon-users" />
                    ⁠Contact Us Queries
                  </Link>
                </Accordion.Button>
              </Accordion.Item>

              <Accordion.Item as="li" className="bg-transparent border-0" eventKey="5">
                <Accordion.Button className="accordion-button-normal">
                  <Link onClick={() => closeLeftSidebar()} to={adminRouteMap.NEWSLETTER.path} className={` ${pathname === adminRouteMap.NEWSLETTER.path ? "active" : ""}`}>
                    <span className="icon icon-users" />
                    Newsletters
                  </Link>
                </Accordion.Button>
              </Accordion.Item>
            </ul>
          </Accordion>
        </SimpleBar>
        {/* <div className="sidebar_footer">
          <ImageElement source="sidebar-img.svg" alt="sidebar" />
        </div> */}
      </div>
  );
}

export default AdminSidebar;
