import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { BreadcrumbComponent, DataTable, DotSpinner, SweetAlert, modalNotification } from "../../../components";
import { Link } from "react-router-dom";
import adminRouteMap from "../../../routes/Admin/adminRouteMap";
import { get, del } from "../../../utils/apiHelpers";
import useDebounce from "../../../hooks/useDebounce";
import { convertDateFormat } from "../../../utils/dateFormatter";

function Newsletter() {
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [queryToDelete, setQueryToDelete] = useState(null);
    const [contact, setContact] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalProducts, setTotalProducts] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [sort, setSort] = useState('latest');

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const getNewsletterList = async () => {
        setLoading(true);
        try {
            const res = await get(`/contacts?page=${page}&pageSize=${pageSize}&sort=${sort}&search=${debouncedSearchTerm}&type=newsletter`);
            if (res?.data?.success) {
                setContact(res.data.result);
                setTotalProducts(res.data.totalEntries);
            }
        } catch (error) {
            console.error('Products error:', error);
            modalNotification({ type: 'error', message: error?.response?.data?.message || error?.response?.data?.error });
        } finally {
            setLoading(false);
        }
    };

    const deleteQueryModal = (contactQuery) => {
        setIsAlertVisible(true);
        setQueryToDelete(contactQuery);
    };

    const onConfirmAlertModal = async () => {
        try {
            const res = await del(`/contacts/${queryToDelete.contactID}`);
            if (res?.data?.success) {
                modalNotification({ type: 'success', message: res?.data?.message });
                setIsAlertVisible(false);
                getNewsletterList();
            }
        } catch (error) {
            console.error('Products error:', error);
            modalNotification({ type: 'error', message: error?.response?.data?.message || error?.response?.data?.error });
        }
    };

    useEffect(() => {
        getNewsletterList();
    }, [page, pageSize, debouncedSearchTerm, sort]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const breadcrumb = [
        { path: `${adminRouteMap.DASHBOARD.path}`, name: 'Dashboard' },
        { path: '#!', name: 'Newsletter' }
    ];

    return (
        <>
            <Card className="h-100 mb-0 border-0">
                <Card.Header className="p-3">
                    <div className="pageHeader">
                        <div className="d-sm-flex align-items-center justify-content-between">
                            <div className="pagetitle mb-sm-0 mb-3">
                                <h1 className="title">Newsletter Form Queries</h1>
                                <BreadcrumbComponent breadcrumb={breadcrumb} />
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className="px-0 pt-3">
                    <DataTable headerExtraClass="px-3" footerExtraClass="px-3" onclickFilterHandel={() => { }}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        page={page}
                        pageSize={pageSize}
                        totalEntries={totalProducts}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        sort={sort}
                        setSort={setSort}
                    >
                        <table className="table align-middle table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th className="w-60">Sr.</th>
                                    <th>Email</th>
                                    <th>Query Date</th>
                                    <th>Action</th>
                                    {/* <th>Status</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="8"><DotSpinner /></td>
                                    </tr>
                                ) : contact.length === 0 ? (
                                    <tr>
                                        <td colSpan="8" className="text-center">No Products Found</td>
                                    </tr>
                                ) : contact.map((listItem, index) => (
                                    <tr key={listItem.contactID}>
                                        <td>{(page - 1) * pageSize + index + 1}</td>
                                        {/* <td>
                                            <h5 className="fs-15 font-md my-1">
                                                <Link to={`${adminRouteMap.PRODUCTDETAILS.path}/${product.productID}`} className="link-secondary">
                                                    {product.name}
                                                </Link>
                                            </h5>
                                        </td> */}
                                        <td>{listItem.email}</td>
                                        <td>{convertDateFormat(listItem.createdOn)}</td>
                                        {/* <td>{listItem.status}</td> */}
                                        <td>
                                            <Dropdown className="actionbtn">
                                                <Dropdown.Toggle as="a" className="p-0">
                                                    <em className="icon icon-more-v" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end" className="dropdown-menu-xs">
                                                    <ul className="list-unstyled actionbtn_menu mb-0">
                                                        {/* <li className="actionbtn_item">
                                                            <Link to={`${adminRouteMap.CONTACTQUERYDETAILS.path}/${listItem.contactID}`} className="text-decoration-none">
                                                                <em className="icon icon-file-text" /> View
                                                            </Link>
                                                        </li> */}
                                                        <li className="actionbtn_item">
                                                            <Link to='#' className="text-decoration-none" onClick={() => deleteQueryModal(listItem)}>
                                                                <em className="icon icon-trash-alt" /> Delete
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </DataTable>
                </Card.Body>
            </Card>

            <SweetAlert
                reverseButtons
                title={`Are you sure you want to delete?`}
                text="If you delete this Query from user, it will be permanently deleted."
                show={isAlertVisible}
                icon="error"
                onConfirmAlert={onConfirmAlertModal}
                showCancelButton
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                setIsAlertVisible={setIsAlertVisible}
            />
        </>
    );
}

export default Newsletter;