import { useState, useEffect } from 'react';

/**
 * Custom Hook: useDebounce
 * Delays updating the value until after the specified delay.
 * 
 * @param value The value to be debounced (e.g. a search term)
 * @param delay The debounce delay in milliseconds (default: 500ms)
 * @returns The debounced value
 */
function useDebounce(value, delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export default useDebounce;
