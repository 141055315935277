import React, { useState, useRef, useEffect } from "react";
import { Input, InputNumber } from "antd";
import { Select } from "../../Antd";
import modalNotification from "../../Toster";
import { del, get, post, put } from "../../../utils/apiHelpers";
import { uploadFile } from "../../../utils/fileUpload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import adminRouteMap from "../../../routes/Admin/adminRouteMap";
import { Col, Row } from "react-bootstrap";
import DotSpinner from "../../DotSpinner";

const Product = ({ updateProductID }) => {
  const [step, setStep] = useState(1);
  const [productID, setProductID] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [blend, setBlend] = useState("");
  const [flavour, setFlavour] = useState("");
  const [brandName, setBrandName] = useState("");
  const [origin, setOrigin] = useState("");
  const [roastProfile, setRoastProfile] = useState("");
  const [motto, setMotto] = useState("");
  const [intensity, setIntensity] = useState("");
  const [bestServed, setBestServed] = useState("");
  const [suitableFor, setSuitableFor] = useState("");
  const [productType, setProductType] = useState("");
  const [status, setStatus] = useState(0);
  const [price, setPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [signedURL, setSignedURL] = useState("");
  const [signedCoverURL, setSignedCoverURL] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [coverVariantImage, setCoverVariantImage] = useState(null);
  const [variant, setVariant] = useState("");
  const [weight, setWeight] = useState("");
  const [pending, setPending] = useState(false);
  const [availableStock, setAvailableStock] = useState("");
  const [defaultCoverUrl, setDefaultCoverUrl] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState(null);

  const fileInputRef = useRef(null);
  const fileInputRefCover = useRef(null);
  const productImageInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const arrayOfData = [
    { id: 0, name: "250" },
    { id: 1, name: "500" },
    { id: 2, name: "1000" },
  ];
  const handleWeightChange = (selectedId) => {
    const selectedVariant = arrayOfData.find((item) => item.id === selectedId);

    if (selectedVariant) {
      setWeight(selectedVariant.name);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const editMode = searchParams.get("edit") === "true";

    if (id && editMode) {
      setProductID(id);
      //   fetchProductData(id);
      setStep(2);
    }
  }, [id, location]);

  //   const fetchProductData = async (productId) => {
  //     try {
  //       const res = await get(`/products/${productId}`);

  //       const product = res?.data?.product;
  //       if (res) {
  //         setBlend(product?.blend);
  //         setName(product?.name);
  //         setDescription(product?.description);
  //         setFlavour(product.flavour);
  //         setBrandName(product.brandName);
  //         setOrigin(product.origin);
  //         setRoastProfile(product.roastProfile);
  //         setIntensity(product.intensity);
  //         setBestServed(product.bestServed);
  //         setSuitableFor(product.suitableFor);
  //         setProductType(product.productType);
  //         // Set other fields...
  //         setSignedURL(product.coverURL);
  //       }
  //     } catch (error) {
  //       modalNotification({
  //         type: "error",
  //         message: "Error fetching product data.",
  //       });
  //     }
  //   };
  const getByIdProduct = async () => {
    try {
      const res = await get(`/products/${updateProductID}`);
      if (res) {
        let product = res.data.result;
        setBlend(product?.blend);
        setName(product?.name);
        setDescription(product?.description);
        setFlavour(product.flavour);
        setBrandName(product.brandName);
        setOrigin(product.origin);
        setRoastProfile(product.roastProfile);
        setMotto(product.motto);
        setIntensity(product.intensity);
        setBestServed(product.bestServed);
        setSuitableFor(product.suitableFor);
        setProductType(product.productType);
        setSignedURL(product.signedURL);
        setDefaultCoverUrl(product.coverURL);
        setProductImages(product.images)
      }
    } catch (error) {
      modalNotification({
        type: "error",
        message: "Error fetching product data.",
      });
    }
  };

  useEffect(() => {
    updateProductID !== undefined && getByIdProduct();
  }, [updateProductID]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
      const localURL = URL.createObjectURL(file);
      setSignedURL(localURL);
    }
  };

  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverVariantImage(file);
      const localURL = URL.createObjectURL(file);
      setSignedCoverURL(localURL);
    }
  };

  const handleProductImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setUploadingImage(true);
        const coverURL = await uploadFile(file);
        const res = await post(`/products/${updateProductID}/images`, { coverURL });
        if (res.data.success) {
          const updatedProduct = await get(`/products/${updateProductID}`);
          setProductImages(updatedProduct.data.result.images);
          modalNotification({ type: "success", message: "Image uploaded successfully" });
          if (productImageInputRef.current) {
            productImageInputRef.current.value = '';
          }
        }
      } catch (error) {
        modalNotification({
          type: "error",
          message: error.response?.data?.message || "Error uploading image",
        });
      } finally {
        setUploadingImage(false);
      }
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      setDeletingImageId(imageId);
      const res = await del(`/products/${updateProductID}/images/${imageId}`);
      if (res.data.success) {
        setProductImages(productImages.filter(img => img.imageID !== imageId));
        modalNotification({ type: "success", message: "Image deleted successfully" });
        if (productImageInputRef.current) {
          productImageInputRef.current.value = '';
        }
      }
    } catch (error) {
      modalNotification({
        type: "error",
        message: error.response?.data?.message || "Error deleting image",
      });
    } finally {
      setDeletingImageId(null);
    }
  };

  const handleSubmitStep1 = async (e) => {
    e.preventDefault();
    setPending(true);

    try {
      let coverURL = "";
      if (coverImage) {
        coverURL = await uploadFile(coverImage);
      }

      const payload = {
        name,
        description,
        blend,
        flavour,
        brandName,
        origin,
        roastProfile,
        motto,
        intensity,
        bestServed,
        suitableFor,
        productType,
        coverURL,
      };

      const res = await post("/products", payload);
      if (res.data.success) {
        modalNotification({ type: "success", message: res.data.message });
        setProductID(res.data.insertID);
        setStep(2);
      }
    } catch (error) {
      modalNotification({
        type: "error",
        message: error.response?.data?.error || "Error creating product.",
      });
    } finally {
      setPending(false);
    }
  };

  const handleSubmitStep2 = async (e) => {
    e.preventDefault();
    if (!productID)
      return modalNotification({
        type: "error",
        message: "Product ID is missing!",
      });

    setPending(true);
    try {
      let coverURL = signedCoverURL;
      if (coverVariantImage) {
        coverURL = await uploadFile(coverVariantImage);
      }

      const payload = {
        coverURL,
        price,
        discountPercent,
        availableStock,
        weight,
        status,
      };

      const res = await post(`/products/${productID}/variants`, payload);
      //   const res = await put(`/products/${productID}`, payload);

      if (res.data.success) {
        modalNotification({ type: "success", message: res.data.message });
        navigate(adminRouteMap.PRODUCTS.path);
      }
    } catch (error) {
      modalNotification({
        type: "error",
        message: error.response?.data?.message || "Error updating product.",
      });
    } finally {
      setPending(false);
    }
  };

  const updateProduct = async () => {
    setPending(true);

    try {
      let coverURL = defaultCoverUrl;
      if (coverImage) {
        coverURL = await uploadFile(coverImage);
      }

      const payload = {
        name,
        description,
        blend,
        flavour,
        brandName,
        origin,
        roastProfile,
        motto,
        intensity,
        bestServed,
        suitableFor,
        productType,
        coverURL,
      };

      const res = await put(`/products/${updateProductID}`, payload);
      if (res.data.success) {
        modalNotification({ type: "success", message: res.data.message });
        navigate("/products");
        // setProductID(res.data.insertID);
      }
    } catch (error) {
      modalNotification({
        type: "error",
        message: error.response?.data?.error || "Error creating product.",
      });
    } finally {
      setPending(false);
    }
  };

  return (
    <div className="addProductPage">
      <form onSubmit={step === 1 ? handleSubmitStep1 : handleSubmitStep2}>
        {step === 1 ? (
          <Row>
            <Col lg={12}>
              <div className="form-group chooseFileDesign bg-white rounded-3">
                <div className="mb-4">
                  <label className="form-label">Cover Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="chooseFileDesign_button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Choose File
                      <p>Please select product image .jpg .png .webp</p>
                    </button>
                  </div>

                  {signedURL && (
                    <div className="mt-3">
                      <img
                        src={signedURL}
                        alt="Main Cover"
                        className="rounded-3 border"
                        style={{
                          width: "180px",
                          height: "180px",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  )}
                </div>

                {updateProductID && (<div className="pt-4">
                  <label className="form-label d-block mb-3">Additional Product Images</label>
                  <div className="mb-3">
                    <div className="upload-wrapper position-relative">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleProductImageUpload}
                        className="form-control"
                        disabled={uploadingImage}
                        ref={productImageInputRef}
                        style={{ paddingRight: uploadingImage ? '50px' : '12px' }}
                      />
                      {uploadingImage && (
                        <div className="position-absolute d-flex align-items-center"
                          style={{ right: '15px', top: '50%', transform: 'translateY(-50%)', zIndex: 2 }}>
                          <DotSpinner />
                        </div>
                      )}
                    </div>
                  </div>

                  {productImages.length > 0 && (
                    <div className="d-flex flex-wrap gap-3 mt-4">
                      {productImages.map((image) => (
                        <div
                          key={image.imageID}
                          className="position-relative border rounded-3 bg-white"
                          style={{ width: "180px" }}
                        >
                          <div className="ratio ratio-1x1">
                            <img
                              src={image.signedURL}
                              alt="Product"
                              className="rounded-3"
                              style={{
                                objectFit: "cover",
                                opacity: deletingImageId === image.imageID ? 0.5 : 1,
                                transition: "opacity 0.2s"
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className={`btn ${deletingImageId === image.imageID ? 'btn-secondary' : 'btn-danger'} btn-sm position-absolute d-flex align-items-center justify-content-center shadow-sm`}
                            style={{
                              top: '8px',
                              right: '8px',
                              width: "28px",
                              height: "28px",
                              padding: 0,
                              minWidth: 'auto',
                              borderRadius: '6px'
                            }}
                            onClick={() => handleDeleteImage(image.imageID)}
                            disabled={deletingImageId === image.imageID}
                          >
                            {deletingImageId === image.imageID ? (
                              <div style={{ transform: 'scale(0.6)' }}>
                                <DotSpinner />
                              </div>
                            ) : (
                              '×'
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>)}
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Product name</label>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Description</label>
                <Input
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Blend</label>
                <Input
                  placeholder="Blend"
                  value={blend}
                  onChange={(e) => setBlend(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Flavour</label>
                <Input
                  placeholder="Flavour"
                  value={flavour}
                  onChange={(e) => setFlavour(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Brand Name</label>
                <Input
                  placeholder="Brand Name"
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>

            {/* <Col lg={6}>
                            <div className='form-group'>
                                <label className='form-label'>Weight</label>
                                <Input placeholder='Weight' value={weight} onChange={e => setWeight(e.target.value)} required className='form-control' />
                            </div>
                        </Col> */}
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Origin</label>
                <Input
                  placeholder="Origin"
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Roast Profile</label>
                <Input
                  placeholder="Roast Profile"
                  value={roastProfile}
                  onChange={(e) => setRoastProfile(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Motto</label>
                <Input
                  placeholder="Motto"
                  value={motto}
                  onChange={(e) => setMotto(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Intensity</label>
                <Input
                  placeholder="Intensity"
                  value={intensity}
                  onChange={(e) => setIntensity(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Best Served</label>
                <Input
                  placeholder="Best Served"
                  value={bestServed}
                  onChange={(e) => setBestServed(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Suitable For</label>
                <Input
                  placeholder="Suitable For"
                  value={suitableFor}
                  onChange={(e) => setSuitableFor(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Product Type</label>
                <Input
                  placeholder="Product Type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={6}>
              {/* <div className="form-group">
                <label className="form-label">Select Variant</label>
                <Select
                  placeholder="Select variant"
                  arrayOfData={[
                    { id: 0, name: "250 gm" },
                    { id: 1, name: "500 gm" },
                    { id: 2, name: "1 kg" },
                  ]}
                  value={variant}
                  onSelectChange={setVariant}
                  required
                />
              </div> */}
              <div className="form-group">
                <label className="form-label">Select Variant</label>
                <Select
                  placeholder="Select variant"
                  arrayOfData={arrayOfData}
                  //   value={arrayOfData.findIndex((item) => item.name === weight)}
                  value={weight ? weight : arrayOfData[0]?.id}
                  onSelectChange={handleWeightChange}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Product Active/Inactive</label>
                <Select
                  placeholder="Product Active/Inactive"
                  arrayOfData={[
                    { id: 0, name: "Inactive" },
                    { id: 1, name: "Active" },
                  ]}
                  value={status}
                  onSelectChange={setStatus}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Price</label>
                <InputNumber
                  placeholder="Price"
                  value={price}
                  onChange={setPrice}
                  min={0}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Discount Price</label>
                <InputNumber
                  placeholder="Discount Price"
                  value={discountPercent}
                  onChange={setDiscountPercent}
                  min={0}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label className="form-label">Available Stock</label>
                <InputNumber
                  placeholder="Available Stock"
                  value={availableStock}
                  onChange={setAvailableStock}
                  min={0}
                  required
                  className="form-control"
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group chooseFileDesign">
                <label className="form-label">Cover Image</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCoverFileChange}
                  ref={fileInputRefCover}
                  style={{ display: "none" }}
                />
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="chooseFileDesign_button"
                    onClick={() => fileInputRefCover.current.click()}
                  >
                    Choose File
                  </button>
                </div>
                {signedCoverURL && (
                  <div className="mt-4 chooseFileDesign_previewImage">
                    <img
                      src={signedCoverURL}
                      alt="Cover"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        <div className="d-flex justify-content-center">
          {/* {step === 2 && (
            <button
              type="button"
              onClick={() => setStep(1)}
              className="btn btn-light me-4"
            >
              Back
            </button>
          )} */}

          {updateProductID ? (
            <button
              //   type="submit"
              className="btn btn-primary"
              disabled={pending}
              onClick={() => updateProduct()}
            >
              {pending ? "Processing..." : "Update Product"}
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={pending}
            >
              {pending ? "Processing..." : step === 1 ? "Save & Next" : "Save"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Product;
