import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { BreadcrumbComponent, modalNotification } from "../../../../components";
import adminRouteMap from "../../../../routes/Admin/adminRouteMap";
import { useParams } from "react-router-dom";
import { getToken } from "../../../../utils/auth";
import { get } from "../../../../utils/apiHelpers";

const ContactQueryDetails = () => {
    const breadcrumb = [
        {
            path: `${adminRouteMap.DASHBOARD.path}`,
            name: 'Dashboard'
        },
        {
            path: '#!',
            name: 'Contact Query Details'
        }
    ];

    let { contactID } = useParams();
    const [contact, setContact] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchContactData()
    }, [])

    const fetchContactData = async () => {
        setLoading(true);
        try {
            const token = await getToken();
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            };
            const res = await get(`/contacts/${contactID}`, {
                headers: headers,
            });

            if (res.data.success) {
                const contact = res.data.result
                setContact(contact)
            }
        } catch (error) {
            // console.log('error', error);

            modalNotification({ type: "error", message: error.response.data.message })
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card className="h-100 mb-0 border-0">
            <Card.Header className="p-3" style={{ background: 'rgba(179, 195, 138, 0.1)' }}>
                <div className="pageHeader">
                    <div className="d-sm-flex align-items-center justify-content-between">
                        <div className="pagetitle mb-sm-0 mb-3">
                            <h1 className="title">Contact Query Details</h1>
                            <BreadcrumbComponent breadcrumb={breadcrumb} />
                        </div>
                    </div>
                </div>
            </Card.Header>

            

            <Card.Body className="p-3">
                <div>
                    <div className="basicDetails_left">
                        <div className="row">
                            <div className="col-6">
                                <div className="basicDetails_left_item mb-3">
                                    <h4 className="fs-16 font-sb">Full Name</h4>
                                    <p className="fs-15 font-lt mb-0">{contact.name}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="basicDetails_left_item mb-3">
                                    <h4 className="fs-16 font-sb">Mail</h4>
                                    <p className="fs-15 font-lt mb-0">{contact.email}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="basicDetails_left_item mb-3">
                                    <h4 className="fs-16 font-sb">Contact Phone</h4>
                                    <p className="fs-15 font-lt mb-0">{contact.phone}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="basicDetails_left_item mb-3">
                                    <h4 className="fs-16 font-sb">Company Site</h4>
                                    <p className="fs-15 font-lt mb-0">keenthemes.com</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="basicDetails_left_item mb-3">
                                    <h4 className="fs-16 font-sb">Country</h4>
                                    <p className="fs-15 font-lt mb-0">Germany</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="basicDetails_left_item mb-0">
                                    <h4 className="fs-16 font-sb">Communication</h4>
                                    <p className="fs-15 font-lt mb-0">Email, Phone</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="basicDetails_left_item mb-0">
                                    <h4 className="fs-16 font-sb">Allow Changes</h4>
                                    <p className="fs-15 font-lt mb-0">YesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYesYes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ContactQueryDetails;