import React, { useState, useRef, useEffect } from "react";
import { Input } from "antd";
import { Select } from "../../Antd";
import modalNotification from "../../Toster";
import { post, put } from "../../../utils/apiHelpers";
import { uploadFile } from "../../../utils/fileUpload";
import { Link, useNavigate } from "react-router-dom";
import adminRouteMap from "../../../routes/Admin/adminRouteMap";
import TextEditor from "../../TextEditor";

const Blog = ({
  blogID,
  title: existingTitle,
  author: existingAuthor,
  category: existingCategory,
  status: existingStatus,
  content: existingContent,
  coverURL,
  signedURL: existingSignedURL,
}) => {
  const [title, setTitle] = useState(existingTitle || "");
  const [author, setAuthor] = useState(existingAuthor || "");
  const [category, setCategory] = useState(existingCategory || "");
  const [status, setStatus] = useState(existingStatus || "0");
  const [content, setContent] = useState(existingContent || "");
  const [signedURL, setSignedURL] = useState(existingSignedURL || "");
  const [coverImage, setCoverImage] = useState(null);
  const [pending, setPending] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
      const localURL = URL.createObjectURL(file);
      setSignedURL(localURL);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setPending(true);
      if (coverImage) {
        coverURL = await uploadFile(coverImage);
      }

      const payload = {
        title,
        author,
        category,
        status,
        content,
        coverURL,
      };
      
      // console.log("payload---", payload);

      let res;
      if (blogID) {
        res = await put(`/blogs/${blogID}`, payload);
      } else {
        res = await post("/blogs", payload);
      }

      if (res.data.success) {
        navigate("/blogs");
        modalNotification({ type: "success", message: res?.data?.message });
        !blogID && navigate(adminRouteMap.BLOGS.path);
      }
    } catch (error) {
      console.error("Error in adding/updating blog:", error);
      modalNotification({
        type: "error",
        message: error?.response?.data?.message || error?.response?.data?.error,
      });
    } finally {
      setPending(false);
    }
  };
      // console.log("status---", status);
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="form-label">Title</label>
        <Input
          name="title"
          type="text"
          className="form-control"
          placeholder="Enter Blog Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label className="form-label">Author</label>
        <Input
          name="author"
          type="text"
          className="form-control"
          placeholder="Enter Author Name"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label className="form-label">Category</label>
        <Input
          name="category"
          type="text"
          className="form-control"
          placeholder="Enter Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label className="form-label">Status</label>
        <div className="form-control-select">
          <Select
            arrayOfData={[
              { id: 0, name: "Draft" },
              { id: 1, name: "Published" },
            ]}
            value={status}
            onSelectChange={(value) => setStatus(value)}
            required
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Cover Image</label>
        {signedURL && (
          <div className="mt-4">
            <img
              src={signedURL}
              alt="Cover"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
        )}

        {!signedURL && coverURL && (
          <div className="mt-4 p-3 bg-light border rounded">
            <p>
              An image URL is set, but no preview is available. The image may be
              loading or unavailable.
            </p>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <div className="d-flex align-items-center mt-4">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => fileInputRef.current.click()}
          >
            Choose File
          </button>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Content</label>
        <TextEditor
          initialValue={existingContent || ""}
          onEditorChange={handleEditorChange}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Link to={adminRouteMap.BLOGS.path}>
          <button className="btn btn-md btn-light me-4">Cancel</button>
        </Link>
        <button className="btn btn-primary" type="submit" disabled={pending}>
          {pending ? "Saving..." : blogID ? "Update Blog" : "Add Blog"}
        </button>
      </div>
    </form>
  );
};

export default Blog;
