
const adminRouteMap = {
  LOGIN: { path: `/`},
  DASHBOARD: { path: `/dashboard`},
  FORGOTPASSWORD: { path: `/forgot-password`},
  CUSTOMERS: { path: `/customers`},
  SELLER: { path: `/seller`},
  MANAGEORDER: {path: `/manage-orders`},
  ORDERDETAILS: {path: `/order-details`},
  // ACTIVEORDERS: { path: `/active-orders`},
  // COMPLETEDORDERS: { path: `/completed-orders`},
  // CANCELLEDORDERS: { path: `/cancelled-orders`},
  FORMELEMENT: { path: `/form-element`},
  PRIVACYPOLICY: { path: `/privacy-policy`},
  TERMSANDCONDITIONS: { path: `/terms-and-condition`},
  RESETPASSWORD: { path: `/reset-password` },
  USERS: { path: `/users` },
  USERDETAILS: { path: `/user-details` },
  EDITUSER: { path: `/edit-user` },
  BLOGS: { path: `/blogs` },
  BLOGDETAILS: { path: `/blog-details` },
  ADDBLOG: { path: `/add-blog` },
  EDITBLOG: { path: `/edit-blog` },
  PRODUCTS: { path: `/products` },
  PRODUCTDETAILS: { path: `/product-details` },
  ADDPRODUCT: { path: `/add-product` },
  EDITPRODUCT: { path: `/edit-product` },
  WHOLESALE: { path: `/wholesale` },
  WHOLESALEDETAILS: { path: `/wholesale-details` },
  CONTACTQUERY: { path: `/contact-query` },
  CONTACTQUERYDETAILS: { path: `/contact-details` },
  NEWSLETTER: { path: `/newsletter` },
};
export default adminRouteMap;