import adminRouteMap from "./adminRouteMap";
import { AdminDashboard, AddBlog, EditBlog, Blogs, BlogDetails, AddProduct, EditProduct, Products, ProductDetails, Users, FormElement, PrivacyPolicy, Seller, TermsConditions, UsreDetails, ContactQuery, ManageOrders, OrderDetails, WholesaleDetails } from "../../pages";
import EditUser from "../../pages/Admin/Users/EditUser/index.page";
import Wholesale from "../../pages/Admin/Wholesale/index.page";
import ContactQueryDetails from "../../pages/Admin/ContactQuery/ContactQueryDetails/index.page";
import Newsletter from "../../pages/Admin/Newsletter/index.page";

export default function route() {
  return [
    {
      path: adminRouteMap.DASHBOARD.path,
      name: "Dashboard",
      element: <AdminDashboard />,
    },
    {
      path: adminRouteMap.USERS.path,
      name: "Users",
      element: <Users />,
    },
    {
      path: adminRouteMap.BLOGS.path,
      name: "Blogs",
      element: <Blogs />,
    },
    {
      path: `${adminRouteMap.BLOGDETAILS.path}/:blogID`,
      name: "Blog Details",
      element: <BlogDetails />,
    },
    {
      path: adminRouteMap.ADDBLOG.path,
      name: "Add Blog",
      element: <AddBlog />,
    },
    {
      path: `${adminRouteMap.EDITBLOG.path}/:blogID`,
      name: "Edit Blog",
      element: <EditBlog />,
    },
    {
      path: adminRouteMap.PRODUCTS.path,
      name: "Products",
      element: <Products />,
    },
    {
      path: `${adminRouteMap.PRODUCTDETAILS.path}/:productID`,
      name: "Product Details",
      element: <ProductDetails />,
    },
    {
      path: adminRouteMap.ADDPRODUCT.path,
      name: "Add Product",
      element: <AddProduct />,
    },
    {
      path: `${adminRouteMap.EDITPRODUCT.path}/:productID`,
      name: "Edit Product",
      element: <EditProduct />,
    },
    {
      path: adminRouteMap.SELLER.path,
      name: "Users",
      element: <Seller />,
    },
    {
      path: `${adminRouteMap.USERDETAILS.path}/:userID`,
      name: "User Details",
      element: <UsreDetails />,
    },
    {
      path: `${adminRouteMap.EDITUSER.path}/:userID`,
      name: "Edit User ",
      element: <EditUser />,
    },
    {
      path: adminRouteMap.FORMELEMENT.path,
      name: "Form Element",
      element: <FormElement />,
    },
    {
      path: adminRouteMap.PRIVACYPOLICY.path,
      name: "Privacy Policy",
      element: <PrivacyPolicy />,
    },
    {
      path: adminRouteMap.TERMSANDCONDITIONS.path,
      name: "Terms and COnditions",
      element: <TermsConditions />,
    },
    {
      path: adminRouteMap.BLOGS.path,
      name: "Blogs",
      element:<Blogs />,
    },
    {
      path: adminRouteMap.WHOLESALE.path,
      name: "Wholesale",
      element:<Wholesale />,
    },
    {
      path: `${adminRouteMap.WHOLESALEDETAILS.path}/:wholesaleID`,
      name: "Wholesale Details",
      element:<WholesaleDetails />,
    },
    {
      path: adminRouteMap.CONTACTQUERY.path,
      name: "Contact Queries",
      element:<ContactQuery />,
    },
    {
      path: `${adminRouteMap.CONTACTQUERYDETAILS.path}/:contactID`,
      name: "Contact Details",
      element:<ContactQueryDetails />,
    },
    {
      path: adminRouteMap.MANAGEORDER.path,
      name: "Manage Orders",
      element:<ManageOrders />,
    },
    {
      path: `${adminRouteMap.ORDERDETAILS.path}/:orderID`,
      name: "Order Details",
      element:<OrderDetails />,
    },
    {
      path: `${adminRouteMap.NEWSLETTER.path}`,
      name: "Newsletter",
      element:<Newsletter />,
    },
  ];
}
