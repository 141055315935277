import React, { useEffect, useState } from 'react';
import { Input as TextInput } from '../../Antd';
import { Link } from 'react-router-dom';
import ModalComponent from '../../Modal';
import ImageElement from '../../ImageElement';
import { useAuth } from '../../../contexts/AuthProvider';

function AdmindetailsSidebar({ closeSidebar, extraClassName = '' }) {
    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);

    const [userDetails, setUserDetails] = useState({
        name: user?.name || '',
        email: user?.email || '',
        signedURL: user?.signedURL || '',
        coverURL: user?.coverURL || ''
    });


    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (user) {
            setUserDetails({
                ...userDetails,
                ...user
            });
        }
    }, [user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const localURL = URL.createObjectURL(file);
            setUserDetails(prevDetails => ({ ...prevDetails, signedURL: localURL }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Profile updated:', userDetails);
        setShowModal(false);
    };

    return (
        <>
            <div className={`profileSidebar ${extraClassName}`}>
                <Link to='#' onClick={closeSidebar} className="closeSidebar">
                    <em className="icon icon-cross" />
                </Link>

                <div className="profileSidebar_imgsec position-relative">
                    <div className="coverImg">
                        <ImageElement source="profile-cover.png" className='img-fluid' alt="cover-img" />
                    </div>
                    <div className="mainImg uploadImg position-absolute">
                        <ImageElement source="avatar/avatar-3.jpg" previewSource={userDetails.signedURL} className='img-fluid' alt="profile-img" />
                    </div>
                </div>

                <div className="profileSidebar_cnt">
                    <div className="profileDetail text-center">
                        <h3>{userDetails.name}</h3>
                        <h4 className='mb-0'>{userDetails.email}</h4>
                        <Link to='#' onClick={() => setShowModal(true)} className='btn btn-sm btn-light d-inline-block'>Edit Profile</Link>
                    </div>
                </div>
            </div>

            <ModalComponent
                modalHeader='Edit Profile'
                size='md'
                show={showModal}
                onHandleCancel={handleCloseModal}
            >
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <div className="mainImg uploadImg position-relative">
                                <ImageElement source="avatar/avatar-3.jpg" previewSource={userDetails.signedURL} className='img-fluid' alt="cover-img" />
                                <label htmlFor="upload-img1">
                                    <input type="file" id="upload-img1" onChange={handleFileChange} />
                                    <em className="icon icon-camera-fill" />
                                </label>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Name</label>
                            <TextInput
                                name='name'
                                type='text'
                                className='form-control'
                                placeholder='Enter name'
                                value={userDetails.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Email</label>
                            <TextInput
                                name='email'
                                type='text'
                                className='form-control'
                                placeholder='Enter email'
                                value={userDetails.email}
                                readOnly
                                icon={
                                    <div className='fieldicon-right'>
                                        <em className='icon icon-lock' />
                                    </div>
                                }
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <button className='btn btn-md btn-light me-4' onClick={handleCloseModal}>Cancel</button>
                            <button className='btn btn-primary' type='submit'>Save</button>
                        </div>
                    </form>
                </div>
            </ModalComponent>
        </>
    );
}

export default AdmindetailsSidebar;