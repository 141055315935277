import React from "react";

export const AdminLogin = React.lazy(() => import("./Login/index.page"));
export const ForgotPassword = React.lazy(() => import("./ForgotPassword/index.page"));
export const AdminDashboard = React.lazy(() => import("./Dashboard/index.page"));
export const Users = React.lazy(() => import("./Users/index.page"));
export const BlogDetails = React.lazy(() => import("./Blogs/BlogDetails/index.page"));
export const ContactQuery = React.lazy(() => import("./ContactQuery/index.page"));
export const ProductDetails = React.lazy(() => import("./Products/ProductDetails/index.page"));
export const Seller = React.lazy(() => import("./Seller/index.page"));
export const UsreDetails = React.lazy(() => import("./Users/UserDetails/index.page"));
export const FormElement = React.lazy(() => import("./FormElement/index.page"));
export const ResetPassword = React.lazy(() => import("./ResetPassword/index.page"));
export const Blogs = React.lazy(() => import("./Blogs/index.page"));
export const AddBlog = React.lazy(() => import("./Blogs/AddBlog/index.page"));
export const EditBlog = React.lazy(() => import("./Blogs/EditBlog/index.page"));
export const Products = React.lazy(() => import("./Products/index.page"));
export const AddProduct = React.lazy(() => import("./Products/AddProduct/index.page"));
export const EditProduct = React.lazy(() => import("./Products/EditProduct/index.page"));
export const Wholesale = React.lazy(() => import("./Wholesale/index.page"));
export const WholesaleDetails = React.lazy(() => import("./Wholesale/WholesaleDetails/index.page"));
export const ManageOrders = React.lazy(() => import("./ManageOrders/index.page"));
export const OrderDetails = React.lazy(() => import("./ManageOrders/OrderDetails/index.page"));

export * from './ManageCms';