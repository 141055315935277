import React, { useState, useRef, useEffect } from "react";
import { Input } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { modalNotification } from "../../../../components";
import { get, put } from "../../../../utils/apiHelpers";
import { uploadFile } from "../../../../utils/fileUpload";
import adminRouteMap from "../../../../routes/Admin/adminRouteMap";

const EditUser = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    phone: "",
    signedURL: "",
    coverURL: "",
  });
  const [coverImage, setCoverImage] = useState(null);
  const [pending, setPending] = useState(false);
  const fileInputRef = useRef(null);
  let { userID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (userID) {
      fetchUserData();
    }
  }, [userID]);

  const fetchUserData = async () => {
    try {
      const res = await get(`/users/${userID}`);
      if (res.data.success) {
        const user = res.data.user;
        setUserDetails({
          name: user.name,
          email: user.email,
          phone: user.phone,
          signedURL: user.signedURL,
          coverURL: user.coverURL,
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      modalNotification({
        type: "error",
        message: "Failed to load user details",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
      const localURL = URL.createObjectURL(file);
      setUserDetails({
        ...userDetails,
        signedURL: localURL,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setPending(true);
      let coverURL = userDetails.coverURL;

      if (coverImage) {
        coverURL = await uploadFile(coverImage);
      }

      const { signedURL, ...payload } = userDetails;
      payload.coverURL = coverURL;

      const res = await put(`/users/${userID}`, payload);

      if (res.data.status) {
        navigate("/users");
        modalNotification({ type: "success", message: res?.data?.message });
      }
    } catch (error) {
      console.error("Error updating user:", error);
      modalNotification({
        type: "error",
        message: error?.response?.data?.error,
      });
    } finally {
      setPending(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="form-label">Name</label>
        <Input
          name="name"
          type="text"
          className="form-control"
          placeholder="Enter Name"
          value={userDetails.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label className="form-label">Email</label>
        <Input
          name="email"
          type="email"
          className="form-control"
          placeholder="Enter Email"
          value={userDetails.email}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label className="form-label">Phone</label>
        <Input
          name="phone"
          type="text"
          className="form-control"
          placeholder="Enter Phone"
          value={userDetails.phone}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label className="form-label">Cover Image</label>
        {userDetails.signedURL && (
          <div className="mt-4">
            <img
              src={userDetails.signedURL}
              alt="Cover"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
        )}

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <div className="d-flex align-items-center mt-4">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => fileInputRef.current.click()}
          >
            Choose File
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Link to={adminRouteMap.USERS.path}>
          <button className="btn btn-md btn-light me-4">Cancel</button>
        </Link>
        <button className="btn btn-primary" type="submit" disabled={pending}>
          {pending ? "Updating..." : "Update User"}
        </button>
      </div>
    </form>
  );
};

export default EditUser;
