import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tabs, Tab, Button } from "react-bootstrap";
import adminRouteMap from '../../../routes/Admin/adminRouteMap';
import AdmindetailsSidebar from "../AdminSidebar";
import ImageElement from "../../ImageElement";
import { useAuth } from "../../../contexts/AuthProvider";
// import AdminSidebar from "../Sidebar";

function AdminHeader({ sideBarOpen }) {

  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth()
  const openSidebar = () => {
    setIsOpen(!isOpen);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    auth.logOut();
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  return (
    <>
      <header className="header">
        <div className="d-flex align-items-center justify-content-end w-100 h-100">
          {/* <h2>1</h2> */}
          <div className="nk-header-brand d-xl-none me-auto d-flex align-items-center">
            <div to="#" className="toggleLeftSidebar me-2 me-lg-3 d-flex" onClick={sideBarOpen}>
              <em className="icon icon-align-left" />
            </div>
            <Link to={adminRouteMap.DASHBOARD.path} className="logo">
              <ImageElement source="logo_light.svg" alt="logo" />
            </Link>
          </div>
          <div className="d-flex align-items-center h-100">
            <div className="userProfile">
              <Link to="#" className="toggleSidebar" onClick={openSidebar}>
                <div className="avatar avatar-sm rounded-circle">
                  <ImageElement source="avatar/avatar-3.jpg" previewSource={auth?.user?.signedURL} className="avatar-img img-fluid" alt="userimg" />
                </div>
              </Link>
            </div>
            <Button onClick={handleLogout} className="header_signout d-inline-flex align-items-center justify-content-center">
              <em className="icon icon-arrow-from-right" /> <span>Sign out</span></Button>
          </div>
        </div>

        <AdmindetailsSidebar
          closeSidebar={closeSidebar}
          extraClassName={`${isOpen ? 'open' : ''}`}
        />

        <div
          className={`overlay ${isOpen ? 'active' : ''}`}
          onClick={closeSidebar}>
        </div>
      </header>
    </>
  );
}

export default AdminHeader;
