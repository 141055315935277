import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Select } from '../Antd';
import SimpleBar from 'simplebar-react';

const DataTable = ({
    children,
    tableHeader = true,
    tableFooter = true,
    footerExtraClass = "",
    headerExtraClass = "",
    filtered = false,
    onclickFilterHandel,
    tableWrapClass = "table-responsive",
    isTable = true,
    page,
    pageSize,
    sort,
    setSort,
    totalEntries,
    setPage,
    setPageSize,
    searchTerm,
    handleSearchChange,
    showSearch = true,
    recentlyAdded = 0
}) => {
    const arrayData = [
        { id: 10, name: 10 },
        { id: 20, name: 20 },
        { id: 50, name: 50 },
        { id: 100, name: 100 }
    ];
    const totalPages = Math.ceil(totalEntries / pageSize);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
    return (
        <div className="datatable dt-bootstrap4 no-footer">
            {tableHeader &&
                <div className={headerExtraClass}>
                    <Row className="justify-between g-2 mb-3">
                        <Col xs={7} sm={6} md={4} className="text-start">
                            {showSearch &&
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <div className='datatable_search'>
                                        <div className='form-group mb-0'>
                                            <label className='position-relative'>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Type in to Search"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                                <div className='datatable_search_icon position-absolute'>
                                                    <em className='icon icon-search' />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                recentlyAdded > 0 &&
                                <>
                                    <strong>  <h6 as="h5">Recent Orders</h6></strong>
                                    <p className="text-muted mb-0 fs-14">
                                        {recentlyAdded || 0} ordered in last 7 days
                                    </p>
                                </>
                            }
                        </Col>
                        <Col xs={5} sm={6} md={8} className="text-end">
                            <div className="datatable-filter h-100">
                                <div className="d-flex align-items-center justify-content-end g-2 h-100">
                                    <div className="dataTables_length" id="DataTables_Table_0_length">
                                        <label className='d-flex align-items-center h-100'>
                                            <span className="d-none d-sm-inline-block me-2 fs-15">Show</span>
                                            <div className="form-control-select">
                                                <Select
                                                    defaultValue={pageSize}
                                                    arrayOfData={arrayData}
                                                    onChange={(value) => {
                                                        setPageSize(value);
                                                        setPage(1);
                                                    }}
                                                    className='showData'
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div className="dataTables_length" id="DataTables_Table_0_length">
                                        <label className='d-flex align-items-center h-100'>
                                            <span className="d-none d-sm-inline-block mx-2 fs-15">Sort</span>
                                            <div className="form-control-select">
                                                <Select
                                                    defaultValue={sort}
                                                    arrayOfData={[
                                                        { id: "latest", name: "Latest" },
                                                        { id: "oldest", name: "Oldest" }
                                                    ]}
                                                    onChange={(value) => {
                                                        setSort(value);
                                                    }}
                                                    className='latest-and-oldest-select'
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    {filtered &&
                                        <div className='filterData ms-sm-3 ms-2'>
                                            <Link to='#' className='filterData_icon' onClick={onclickFilterHandel}>
                                                <em className='icon icon-filter' />
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {
                isTable ?
                    <SimpleBar className={`datatable-wrap ${tableWrapClass}`}>
                        {children}
                    </SimpleBar>
                    :
                    children
            }
            {tableFooter &&
                <div className={footerExtraClass}>
                    <Row className="align-items-center mt-3">
                        <Col xs="7" sm="12" md="9">
                            <div className="datatable_paginate">
                                <ul className="pagination mb-0">
                                    <li className={`paginate_button page-item previous ${page === 1 ? 'disabled' : ''}`}>
                                        <Link to="#" className="page-link" onClick={() => setPage(page - 1)}>
                                            <em className="icon-back-ios" />
                                        </Link>
                                    </li>

                                    {pageNumbers.slice(Math.max(page - 3, 0), Math.min(page + 2, pageNumbers.length)).map(number => (
                                        <li key={number} className={`paginate_button page-item ${page === number ? 'active' : ''}`}>
                                            <Link to="#" className="page-link" onClick={() => setPage(number)}>
                                                {number}
                                            </Link>
                                        </li>
                                    ))}

                                    <li className={`paginate_button page-item next ${totalEntries <= page * pageSize ? 'disabled' : ''}`}>
                                        <Link to="#" className="page-link" onClick={() => setPage(page + 1)}>
                                            <em className="icon-forward-ios" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs="5" sm="12" md="3" className="text-start text-md-end">
                            <div className="text-muted mb-0 fs-14 datatable_info">
                                {`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, totalEntries)} of ${totalEntries}`}
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default DataTable;